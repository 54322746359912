const Data=[
  {
    "id": 1,
    "category": "JavaScript",
    "name": "JavaScript Basics",
    "description": "Learn the fundamentals of JavaScript, the language of the web.",
    "image": "/images/js.jpg",
    "price": 499,
    "detailedDescription": "This course introduces you to the fundamentals of JavaScript programming. You will start by understanding the basic syntax, data types, and operators, then move on to control structures, functions, and objects. By the end of the course, you will have the skills to write JavaScript code for both client-side and server-side applications.",
    "duration": "",
    "syllabus":[
    "Introduction to JavaScript and Setup",
    "Understanding Variables, Constants, and Data Types",
    "Basic Operators (Arithmetic, Assignment, Comparison)",
    "Control Flow: if/else, switch, ternary operator",
    "Loops: for, while, do-while",
    "Functions: Declaration, Expression, and Arrow Functions",
    "Understanding Scope and Closures",
    "Objects and Arrays",
    "Manipulating the DOM (Document Object Model)",
    "Event Handling in JavaScript",
    "Introduction to JavaScript ES6+ Features (Let, Const, Arrow Functions, Template Literals)",
    "Asynchronous JavaScript: Callbacks, Promises, and async/await",
    "Error Handling and Debugging",
    "Working with JSON (JavaScript Object Notation)",
    "Basic Introduction to JavaScript Frameworks (Optional)"
  ],

  },
  {
    "id": 2,
    "category": "Python",
    "name": "Python for Beginners",
    "description": "Start coding in Python, a powerful and easy-to-learn programming language.",
    "image": "/images/python.jpg",
    "price": 599,
    "detailedDescription": "This course is designed for beginners who want to start coding in Python. The course covers Python’s basic syntax, control structures, data structures, and how to write functions. You will also learn about object-oriented programming (OOP) concepts and work with libraries for real-world applications such as data manipulation and web development.",
    "duration": "",
    "syllabus":[
      "Introduction to Python and Setup",
      "Variables, Data Types, and Type Casting",
      "Control Flow: if/else, for, while loops",
      "Functions: Defining and Calling Functions",
      "Lists, Tuples, and Dictionaries",
      "Working with Strings and String Methods",
      "File Handling in Python",
      "Introduction to Object-Oriented Programming (OOP)",
      "Exception Handling",
      "Python Libraries (math, random, datetime)",
      "Introduction to Modules and Packages",
      "Working with External Libraries (requests, pandas)",
      "Basic Data Structures: Stacks, Queues, and Linked Lists",
      "Introduction to Web Development with Flask (Optional)"
    ]
  },
  {
    "id": 3,
    "category": "Java",
    "name": "Mastering Java",
    "description": "A comprehensive guide to learning Java for object-oriented programming.",
    "image": "/images/java.jpg",
    "price": 799,
    "detailedDescription": "This course provides a thorough introduction to Java, focusing on object-oriented programming (OOP) principles. Learn how to write robust Java programs, manage memory, handle exceptions, and utilize Java’s standard libraries for real-world applications. The course also introduces advanced Java concepts such as multi-threading, collections framework, and JavaFX for GUI development.",
    "duration": "",
    "syllabus":[
      "Introduction to Java and Setup",
      "Basic Syntax and Data Types",
      "Control Flow: if/else, for, while loops",
      "Methods and Functions",
      "Object-Oriented Programming (OOP) Concepts",
      "Classes, Objects, and Constructors",
      "Inheritance and Polymorphism",
      "Abstraction and Encapsulation",
      "Exception Handling in Java",
      "Java Collections Framework (Lists, Sets, Maps)",
      "Working with Files in Java",
      "Introduction to JavaFX for GUI Development",
      "Multi-threading in Java",
      "Java Streams and Lambdas",
      "Unit Testing with JUnit"
    ]
  },
  {
    "id": 4,
    "category": "C",
    "name": "C Programming Essentials",
    "description": "A beginner's guide to learning C, a foundational language for programming.",
    "image": "/images/c.jpg",
    "price": "free",
    "detailedDescription": "This course introduces C programming, focusing on building a strong foundation for understanding how computers work at the lowest level. Students will learn the syntax, control structures, functions, and memory management in C. This course also covers topics like pointers, arrays, and file handling. By the end of the course, students will be able to write efficient C programs and understand the key concepts that form the basis of more advanced languages.",
    "duration": "",
    "syllabus":["Introduction to C Programming",
    "Setting up the Development Environment",
    "C Syntax, Keywords, and Data Types",
    "Control Structures: if/else, switch, loops",
    "Functions in C",
    "Arrays and Strings",
    "Pointers and Memory Management",
    "Structures in C",
    "File Handling in C",
    "Preprocessor Directives and Macros",
    "Dynamic Memory Allocation",
    "Linked Lists",
    "Error Handling in C",
    "Introduction to Algorithms and Data Structures"
  ]
  },
  {
    "id": 5,
    "category": "Web Design",
    "name": "Web Design Fundamentals",
    "description": "Learn the basics of web design, including layout, typography, and color theory.",
    "image": "/images/webdesign.png",
    "price":"free",
    "detailedDescription": "This course focuses on the core principles of web design, ensuring a strong foundation in designing visually appealing and user-friendly websites. It covers the design process from the initial concept to the finished product, including wireframing, color theory, typography, layout design, and responsive web design. By the end of the course, students will have the skills to create aesthetically pleasing websites with intuitive user interfaces.",
    "duration": "",
    "syllabus": [
      "Introduction to Web Design",
      "The Design Process: Concept to Completion",
      "Wireframing and Prototyping",
      "Color Theory and Design Psychology",
      "Typography for the Web",
      "Layout Design and Grids",
      "Responsive Web Design",
      "Basic HTML and CSS for Web Design",
      "User Experience (UX) Principles",
      "Designing for Different Devices and Screen Sizes",
      "Creating Visual Consistency",
      "Web Accessibility and SEO Principles",
      "Introduction to Web Design Tools (Adobe XD, Figma, Sketch)",
      "Designing for User Interaction"
    ]
  },
  {
    "id": 6,
    "category": "Web Development",
    "name": "Full Stack Web Development",
    "description": "Build complete web applications from front-end to back-end .",
    "image": "/images/webdevelopment.png",
    "price": 999,
    "detailedDescription": "This course covers both front-end and back-end web development, equipping students with the skills needed to build dynamic, interactive web applications. Students will learn the essential technologies and frameworks used in modern web development, including HTML, CSS, JavaScript, Node.js, Express, MongoDB, and React. By the end of the course, students will be able to design, build, and deploy a full-stack web application that is responsive, secure, and scalable.",
    "duration": "",
    "syllabus":[
      "Introduction to Full Stack Development",
      "Setting Up Development Environment (IDE, Node.js, MongoDB)",
      "HTML and CSS Basics",
      "JavaScript Fundamentals",
      "Version Control with Git and GitHub",
      "Responsive Web Design with CSS Flexbox and Grid",
      "Introduction to Front-End Frameworks (React.js)",
      "State Management in React (useState, useContext)",
      "Routing in React with React Router",
      "Introduction to Back-End Development (Node.js and Express.js)",
      "RESTful APIs and HTTP Methods",
      "Connecting Front-End and Back-End with APIs",
      "Database Management with MongoDB",
      "Authentication and Authorization (JWT, Passport.js)",
      "Asynchronous JavaScript (Promises, Async/Await)",
      "Handling Forms and Validations in Express",
      "Deploying Web Applications (Heroku, Netlify)",
      "Testing and Debugging Web Applications",
      "Advanced Topics: WebSockets, Webhooks, and Caching"
    ]
  },
  {
    "id": 7,
    "category": "Django",
    "name": "Django for Web Development",
    "description": "Learn Django to build powerful and secure web applications using Python.",
    "image": "/images/django.jpg",
    "price": 799,
    "detailedDescription": "This course focuses on Django, a high-level Python web framework that allows developers to create secure and scalable web applications quickly. Students will learn how to build dynamic websites and web applications using Django, integrate databases, work with user authentication, and deploy applications. The course covers fundamental topics like templates, views, forms, models, and Django's ORM, as well as more advanced topics like deployment and security practices.",
    "duration": "",
    "syllabus":[
      "Introduction to Django and Python Basics",
      "Setting Up Django Development Environment",
      "Creating a Django Project",
      "Django Models and Database Setup",
      "Django Views and Templates",
      "Handling Forms in Django",
      "Django Admin Interface",
      "Working with Static and Media Files",
      "User Authentication and Authorization",
      "Building RESTful APIs with Django Rest Framework (DRF)",
      "Django's ORM (Object-Relational Mapping)",
      "Django's URL Routing and URL Patterns",
      "Template Inheritance and Context Variables",
      "Deploying Django Applications (Heroku, DigitalOcean)",
      "Security Practices in Django",
      "Error Handling and Debugging in Django"
    ]
  },
  {
    "id": 8,
    "category": "Flask",
    "name": "Flask for Web Development",
    "description": "Master Flask to create lightweight, scalable web applications with Python.",
    "image": "/images/flask.jpg",
    "price": 749,
    "detailedDescription": "This course is designed to teach you Flask, a micro web framework written in Python. Flask is lightweight, flexible, and easy to learn, making it ideal for building small to medium-sized web applications. The course covers the fundamental concepts of Flask, including routing, templates, forms, and databases, as well as more advanced topics like RESTful APIs and application deployment. By the end of the course, you'll be capable of building dynamic web applications and APIs using Flask.",
    "duration": "",
    "syllabus":[
      "Introduction to Flask and Web Development",
      "Setting Up Flask Development Environment",
      "Creating a Basic Flask Application",
      "Flask Routing and URL Mapping",
      "Working with Templates and Jinja2",
      "Handling Forms in Flask",
      "Flask Models and Databases with SQLAlchemy",
      "User Authentication in Flask",
      "Building RESTful APIs with Flask",
      "Flask Blueprints for Organizing Code",
      "Working with Static and Media Files in Flask",
      "Handling Errors and Debugging in Flask",
      "Deployment of Flask Applications (Heroku, DigitalOcean)",
      "Flask Security Best Practices"
    ]
  },
  {
    "id": 9,
    "category": "MERN Stack",
    "name": "MERN Stack Development",
    "description": "Build modern web applications using MongoDB, Express, React, and Node.js.",
    "image": "/images/mern.jpg",
    "price": 899,
    "detailedDescription": "This course covers the complete MERN stack—MongoDB, Express, React, and Node.js. It equips you with the skills to build full-stack web applications using JavaScript. You'll learn to manage the front-end with React, handle server-side logic with Node.js and Express, and work with MongoDB for efficient database management. The course focuses on real-world applications and includes topics like authentication, RESTful API creation, and deploying applications to the cloud. By the end of the course, you'll have the skills to develop modern web applications with MERN stack.",
    "duration": "",
    "syllabus":[
      "Introduction to MERN Stack and Setting Up Development Environment",
      "Basics of JavaScript and ES6+ Features",
      "Introduction to MongoDB and NoSQL Databases",
      "Setting Up MongoDB with Mongoose",
      "Creating RESTful APIs with Node.js and Express",
      "Handling Routing and Middleware in Express",
      "Building the Frontend with React.js",
      "State Management in React using Redux",
      "Connecting React with Express Backend",
      "Authentication and Authorization (JWT, Passport)",
      "CRUD Operations in MongoDB and React",
      "Deployment with Heroku and MongoDB Atlas",
      "Testing MERN Applications",
      "Optimizing MERN Applications for Performance"
    ]
  },
  {
    "id": 10,
    "category": "Full Stack with Python",
    "name": "Full Stack  with Python",
    "description": "Learn full stack development with Python, integrating Django or Flask with front-end technologies.",
    "image": "/images/pythonfullstack.png",
    "price": 109,
    "detailedDescription": "This course provides a comprehensive guide to becoming a full-stack developer with Python. You'll start by learning the basics of Python and web frameworks like Django and Flask for backend development. The course then covers front-end development with HTML, CSS, JavaScript, and React. You’ll learn how to integrate the back-end with the front-end, manage databases with SQL, and deploy your applications. By the end, you’ll have the skills to build full-stack web applications and deploy them to production environments.",
    "duration": "",
    "syllabus": [
      "Introduction to Full Stack Development with Python",
      "Understanding Python Basics and Advanced Concepts",
      "Introduction to Web Development and HTTP",
      "Frontend Development: HTML, CSS, JavaScript Basics",
      "Backend Development with Django or Flask",
      "Database Management with SQL (PostgreSQL/MySQL)",
      "Building RESTful APIs with Django or Flask",
      "Integrating Frontend with Backend (AJAX, Fetch API, etc.)",
      "Authentication and Authorization (JWT, OAuth, etc.)",
      "Frontend Development with React.js",
      "State Management in React using Redux",
      "Deploying Full Stack Applications (Heroku, AWS, Docker)",
      "Testing Full Stack Applications (Unit, Integration, and UI Tests)",
      "Optimizing Full Stack Applications for Performance"
    ]
  },
  {
    "id": 11,
    "category": "Node JS",
    "name": " NodeJS for Backened Development",
    "description": "Explore the world of Ruby and Rails to create web applications.",
    "image": "/images/nodejs.jpg",
    "price": 749,
    "detailedDescription": "This course introduces you to Node.js, an open-source, cross-platform runtime environment that allows you to use JavaScript for backend development. You'll learn how to set up Node.js environments, handle HTTP requests, work with databases, and build APIs. By the end of the course, you’ll be able to develop efficient and scalable web applications using Node.js and integrate them with other technologies like Express.js, MongoDB, and RESTful APIs.",
    "duration": "",
    "syllabus":[
      "Introduction to Node.js and its Architecture",
      "Setting Up the Node.js Development Environment",
      "Core Modules in Node.js (File System, HTTP, Events, etc.)",
      "Handling HTTP Requests and Responses in Node.js",
      "Building RESTful APIs with Node.js and Express.js",
      "Working with Databases (MongoDB, MySQL, or PostgreSQL)",
      "Authentication in Node.js (JWT, OAuth, etc.)",
      "Error Handling and Debugging in Node.js",
      "Building Scalable Applications with Node.js",
      "Asynchronous Programming in Node.js (Callbacks, Promises, Async/Await)",
      "Using External Libraries and NPM (Node Package Manager)",
      "WebSockets and Real-Time Applications with Node.js",
      "Deployment of Node.js Applications to Cloud (Heroku, AWS, etc.)",
      "Testing Node.js Applications (Mocha, Chai, Jest)"
    ]
  },
  {
    "id": 12,
    "category": "Go",
    "name": "Go Programming Language",
    "description": "Learn Go (Golang) for building efficient, scalable systems with fast performance and easy concurrency handling.",
    "image": "/images/go.jpg",
    "price": 649,
    "detailedDescription": "In this course, you'll learn Go, a statically typed, compiled programming language designed for high-performance systems and scalable applications. You'll explore the Go syntax, data structures, and concurrency features, and how to write efficient, multi-threaded applications. The course includes hands-on projects to help you build real-world applications, with an emphasis on building web services and scalable systems.",
    "duration": "",
    "syllabus":[
      "Introduction to Go Programming",
      "Setting Up the Go Development Environment",
      "Go Syntax, Variables, and Constants",
      "Data Types and Control Flow in Go",
      "Functions in Go (Parameters, Return Types, Variadic Functions)",
      "Structs and Interfaces in Go",
      "Concurrency in Go (Goroutines, Channels, Select)",
      "Error Handling in Go",
      "Working with Go Modules and Dependencies",
      "Building Web Applications with Go (HTTP, RESTful APIs)",
      "Go's Standard Library and Common Packages",
      "Testing and Debugging Go Applications",
      "Deploying Go Applications to Production"
    ]
  },
  {
    "id": 13,
    "category": "PHP",
    "name": "PHP and MySQL for Web Development",
    "description": "Dive into server-side programming with PHP and MySQL for web applications.",
    "image": "/images/php.jpg",
    "price": 599,
    "detailedDescription": "In this comprehensive PHP and MySQL course, you'll learn how to build dynamic, database-driven websites and web applications. The course covers everything from the basics of PHP syntax to more advanced concepts like object-oriented programming, database integration with MySQL, and secure coding practices. You’ll also work with session management, file uploads, and error handling while developing a robust understanding of PHP's powerful features for web development.",
    "duration": "",
    "syllabus":  [
      "Introduction to PHP and Web Development",
      "Setting Up the PHP Development Environment",
      "Basic PHP Syntax, Variables, and Constants",
      "Working with Arrays and Loops in PHP",
      "Functions and Methods in PHP",
      "Working with Forms and User Input in PHP",
      "Object-Oriented Programming in PHP (OOP)",
      "Handling Files and Directories in PHP",
      "Introduction to MySQL and Database Concepts",
      "Connecting PHP with MySQL: CRUD Operations",
      "Validating and Sanitizing User Input in PHP",
      "Sessions and Cookies for User Authentication",
      "Error Handling and Debugging in PHP",
      "Building a Simple PHP Web Application",
      "Securing PHP Applications (SQL Injection, XSS, CSRF)",
      "Deploying PHP Applications to the Web"
    ] 
  },
  {
    "id": 14,
    "category": "React js",
    "name": "React JS",
    "description": "Learn React for building dynamic, interactive UIs with reusable components and efficient state management.",
    "image": "/images/reactjs.jpg",
    "price": 899,
    "detailedDescription": "In this React JS course, you'll learn how to build dynamic and scalable user interfaces for web applications using React. Starting from the fundamentals of React, the course will guide you through key concepts such as components, state management, props, lifecycle methods, and hooks. You’ll also learn how to integrate React with APIs, handle user events, and use routing to build single-page applications (SPAs). By the end of the course, you will be able to create modern React applications and use advanced features like React Router and Redux for state management.",
    "duration": "",
    "syllabus": [
      "Introduction to React and Setting Up the Development Environment",
      "Understanding React Components and JSX",
      "State and Props in React",
      "Handling Events in React",
      "React Component Lifecycle Methods",
      "Building and Using Functional Components",
      "Introduction to React Hooks: useState, useEffect",
      "Managing Forms and User Input in React",
      "Routing with React Router",
      "State Management with Redux",
      "Consuming APIs with Fetch and Axios in React",
      "Styling React Components: CSS-in-JS, Styled Components",
      "Debugging React Applications",
      "Deploying React Applications",
      "Building a Full React Application"
    ]
  },
  {
    "id": 15,
    "category": "C++",
    "name": " c++ Programming",
    "description": "Learn C++ for high-performance apps, system programming, and object-oriented design.",
    "image": "/images/c++.jpg",
    "price": 899,
    "detailedDescription": "This C++ course is designed to help you learn the fundamentals of C++ programming and take your skills to the next level. You'll learn about C++ syntax, object-oriented programming, data structures, memory management, and advanced features like templates and multi-threading. By the end of this course, you'll have the skills to write efficient and high-performance applications in C++, from desktop software to system-level programming.",
    "duration": "",
    "syllabus": [
      "Introduction to C++: Setup and Hello World",
      "Understanding C++ Syntax and Data Types",
      "Control Structures: Loops, Conditionals, and Switch Statements",
      "Functions in C++: Declarations, Definitions, and Parameters",
      "Object-Oriented Programming Concepts in C++",
      "Classes and Objects in C++",
      "Memory Management in C++: Pointers and Dynamic Memory Allocation",
      "Advanced Object-Oriented Concepts: Inheritance, Polymorphism, and Encapsulation",
      "Working with Data Structures: Arrays, Strings, Linked Lists",
      "Exception Handling in C++",
      "File Handling in C++",
      "Templates and Generic Programming",
      "Introduction to Multi-threading and Concurrency in C++",
      "STL (Standard Template Library) in C++",
      "C++ for System Programming"
    ]
  }
]
export default Data;